import { Box, Hidden, Typography } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import signupBackground from "../assets/images/signup-background.jpg"
import { BoxWithBackgroundImage } from "../components/backgrounds/BoxWithBackgroundImage"
import { FlexBox } from "../components/FlexBox"
import { BaseLayout } from "../layouts/base"
import { baseTheme, DarkThemeProvider, RadiusProvider } from "../theme"

export const LoginLayout: FunctionComponent = ({ children }) => {
  return (
    <BaseLayout>
      <FlexBox flexDirection={{ xs: "column", lg: "row" }} fullHeight>
        <BoxWithBackgroundImage
          flexBasis={{ lg: "33%" }}
          url={signupBackground}
          color={baseTheme.palette.primary.main}
          position="center top"
          minHeight={150}
          display={{ xs: "flex", lg: "block" }}
          justifyContent={{ xs: "center", lg: "flex-start" }}
          alignItems={{ xs: "center", lg: "flex-start" }}
        >
          <Hidden mdDown>
            <Box px={3} pt={1}>
              <DarkThemeProvider>
                <Typography variant="h2" color="textPrimary">
                  Host and watch your events.
                </Typography>
              </DarkThemeProvider>
            </Box>
          </Hidden>
        </BoxWithBackgroundImage>

        <FlexBox
          flexBasis={{ xs: "100%", lg: "67%" }}
          position={{ xs: "relative", lg: "static" }}
          minHeight={"calc(100vh - 150px)"}
          center
        >
          <RadiusProvider radius="normal">{children}</RadiusProvider>
        </FlexBox>
      </FlexBox>
    </BaseLayout>
  )
}
