import { Box, Typography } from "@material-ui/core"
import { ArrowForward } from "@material-ui/icons"
import { GetStaticProps } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Link from "next/link"
import React from "react"
import { SecondaryButton } from "../components/button/SecondaryButton"
import { FlatLink } from "../components/FlatLink"
import { FlexBox } from "../components/FlexBox"
import { LoginLayout } from "../layouts/login"
import { i18n } from "../next-i18next.config"

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations(i18n.defaultLocale, [])),
    },
  }
}

export default function StrealIndexPage() {
  return (
    <>
      <LoginLayout>
        <FlexBox marginTop="auto" marginBottom="auto" center>
          <Box padding={2}>
            <Typography
              variant="h4"
              style={{ color: "#184B70" }}
              align="center"
            >
              This streaming platform is powered by Streal streaming solutions
            </Typography>
          </Box>

          {/* <Spacer vertical={{ xs: 1, lg: 4 }} /> */}

          <Box width={{ xs: "100%", sm: "50%" }} p={4}>
            <Link href="https://streal.nl" passHref>
              <SecondaryButton endIcon={<ArrowForward />}>
                More info?
              </SecondaryButton>
            </Link>
          </Box>
        </FlexBox>

        <Box padding={2}>
          <Link href="https://digitalpatrol.nl" passHref>
            <FlatLink style={{ color: "gray" }}>
              Web development: Digital Patrol B.V.
            </FlatLink>
          </Link>
        </Box>
      </LoginLayout>
    </>
  )
}
