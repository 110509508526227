import { makeStyles } from "@material-ui/core"
import { AnchorHTMLAttributes, forwardRef, FunctionComponent } from "react"
import { baseTheme } from "../theme"

export const useFlatLinkStyles = makeStyles({
  root: {
    color: baseTheme.palette.secondary.main,
    textDecoration: "none",
  },
})

export const FlatLink: FunctionComponent<
  AnchorHTMLAttributes<HTMLAnchorElement>
> = forwardRef(({ children, ...props }, ref: React.Ref<HTMLAnchorElement>) => {
  const classes = useFlatLinkStyles()

  return (
    <a ref={ref} {...props} className={classes.root}>
      {children}
    </a>
  )
})
